<template>
  <v-app
    v-bind:style="[
      asuulga.bgDetailColor != null
        ? {
            background: asuulga.bgDetailColor.hex,
            'overflow-y': 'hidden',
            'border-color': asuulga.bgDetailColor.hex,
          }
        : {
            background: '#fff',
            'overflow-y': 'hidden',
            'border-color': '#e89e57',
          },
    ]"
    v-if="asuulga"
  >
    <template v-if="sent == false">
      <section v-if="loading == false">
        <v-container v-if="isGrid">
          <v-row no-gutters>
            <v-col md="6" class="mx-auto">
              <v-row justify="space-between">
                <v-col>
                  <h3>
                    {{ asuulga.name }}
                  </h3>
                </v-col>
                <v-col class="text-right">
                  <span class="mx-2">
                    <span id="timeLeft" style="color: red"></span>
                  </span>
                </v-col>
              </v-row>
              <v-row justify="start" class="my-0">
                <v-col>
                  <h4 class="text-start" v-if="userData">
                    Oролцогч:
                    <span v-if="userData.firstName" class="font-weight-bold">{{
                      userData.firstName
                    }}</span>
                    <span v-else class="font-weight-bold">{{
                      userData.email
                    }}</span>
                  </h4>
                </v-col>
              </v-row>

              <v-container>
                <v-row justify="end" class="mr-4 mt-0" v-if="asuulga.slideUrl">
                  <v-btn
                    text
                    color="red"
                    @click="closeAsuulgaSlided = !closeAsuulgaSlided"
                  >
                    <span v-if="closeAsuulgaSlided">Даалгавар нээx</span>
                    <span v-else>Даалгавар xааx</span></v-btn
                  >
                </v-row>
                <div
                  v-if="closeAsuulgaSlided == false"
                  v-html="_getSlideLink(asuulga)"
                  class="mt-4"
                ></div>
              </v-container>
              <h4
                style="color: red; white-space: nowrap"
                class="font-weight-bold"
              >
                Нийт оноо: {{ totalScoreOfAsuuglga }}
              </h4>
              <v-toolbar flat>
                <v-tabs v-model="tab" show-arrows>
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab v-for="(cat, catindex) in categories" :key="catindex">
                    {{ cat.name }} ({{ cat.questions.length
                    }}<span v-if="cat.totalScore"
                      >/
                      <span style="color: red; font-size: 10pt"
                        >Оноо: {{ cat.totalScore }}</span
                      ></span
                    >
                    )
                  </v-tab>
                </v-tabs>
              </v-toolbar>
              <v-tabs-items
                v-model="tab"
                v-bind:style="[
                  asuulga.bgDetailColor != null
                    ? {
                        background: asuulga.bgDetailColor.hex,
                        'overflow-y': 'hidden',
                        'border-color': asuulga.bgDetailColor,
                      }
                    : {
                        background: '#fff',
                        'overflow-y': 'hidden',
                        'border-color': '#e89e57',
                      },
                ]"
              >
                <!-- :border="question.countAnswers > 0 ? 'left' : ''"
                  colored-border -->
                <v-tab-item
                  v-for="(cat, catindex) in categories"
                  :key="catindex"
                >
                  <v-row justify="end" class="mr-4 mt-0" v-if="cat.slideUrl">
                    <v-btn text color="red" @click="closeSlided = !closeSlided">
                      <span v-if="closeSlided">нээx</span>
                      <span v-else>xааx</span></v-btn
                    >
                  </v-row>
                  <div
                    v-if="closeSlided == false"
                    v-html="_getSlideLink(cat)"
                    class="mt-4"
                  ></div>

                  <v-simple-table
                    class="asuulga-table mt-4"
                    style="height: 280px; overflow-y: scroll"
                  >
                    <template v-slot:default>
                      <tbody>
                        <tr
                          v-for="(question, qindex) in cat.questions"
                          :key="qindex"
                        >
                          <td
                            style="width: 1%; background-color: #ececec"
                            class="font-weight-bold"
                          >
                            {{ question.name }}
                          </td>
                          <td
                            class="pa-0"
                            v-for="(answer, aindex) in question.answers"
                            :key="answer.id"
                            @click="
                              answer.answered = !answer.answered;
                              getStudentAnswer(question, aindex, cat);
                            "
                            :class="answer.answered == true ? 'bordercell' : ''"
                          >
                            {{ answer.name }}
                            <!-- // {{ answer.correct }} -->
                            <!-- <div>
                              <v-row>
                                <v-col
                                  
                                >
                                 

                                  <div v-if="answer.score && showScores">
                                    <v-chip color="success">{{
                                      answer.score
                                    }}</v-chip>
                                  </div>
                                </v-col>
                                <v-spacer></v-spacer>
                                <v-btn icon>
                                  <v-icon
                                    small
                                    @click="_clearAnswers(question)"
                                  >
                                    mdi-delete
                                  </v-icon></v-btn
                                >
                              </v-row>
                              <v-row></v-row>
                            </div> -->
                          </td>
                          <td>
                            <small
                              @click="_clearAnswers(question)"
                              class="red--text"
                              style="cursor: pointer"
                              >Арилгаx</small
                            >
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-container>
        <v-container v-else>
          <v-row no-gutters>
            <v-col md="8" offset-md="3">
              <div v-for="(cat, catindex) in categories" v-bind:key="catindex">
                <br />
                <v-btn
                  elevation="0"
                  class="py-4 font-weight-bold my-0"
                  color="primary"
                >
                  БҮЛЭГ. {{ cat.name }}</v-btn
                >
                <div>
                  <v-alert
                    class="pa-4 mt-0 mb-2"
                    color="red"
                    :border="question.countAnswers > 0 ? 'left' : ''"
                    colored-border
                    v-for="(question, qindex) in cat.questions"
                    :key="qindex"
                  >
                    <h4>
                      {{ qindex + 1 }}. {{ question.name }}
                      <span class="red--text">
                        {{ question.countAnswers }}/{{
                          question.answerLimit
                        }}</span
                      >
                    </h4>
                    <v-row justify="start">
                      <v-col
                        cols="2"
                        class="px-1"
                        v-for="answer in question.answers"
                        :key="answer.id"
                        @click="
                          _answerDefaultQuestion(question, answer, category)
                        "
                      >
                        <v-list-item-avatar class="mr-0">
                          <v-icon
                            v-if="answer.answered == true"
                            style="color: red"
                            size="22"
                          >
                            mdi-checkbox-marked-circle
                          </v-icon>
                          <v-icon v-else>
                            mdi-checkbox-blank-circle-outline
                          </v-icon>
                        </v-list-item-avatar>
                        <!-- 
                        <v-list-item-content>
                          <v-list-item-title
                            class="text-wrap"
                            v-text="answer.name"
                          />
                        </v-list-item-content> -->

                        <!-- <v-list-item-action v-if="answer.score">
                        <p>{{ answer.score }}</p>
                      </v-list-item-action> -->
                      </v-col>
                    </v-row>
                    <!-- <v-list>
                     

                      <v-list-item style="min-height: 0px">
                        <v-list-item-content>
                          <v-list-item-title class="text-wrap" />
                        </v-list-item-content>

                        <v-list-item-action
                          class="ma-0"
                          v-if="question.countAnswers > 0"
                        >
                          <v-chip
                            class="font-weight-bold"
                            @click="_clearAnswers(question)"
                          >
                            Арилгаx ({{ question.countAnswers }})</v-chip
                          >
                        </v-list-item-action>
                      </v-list-item>
                    </v-list> -->
                  </v-alert>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <div
        v-else
        style="position: fixed; z-index: 10000000; top: 45%; left: 45%"
      >
        <div class="spinner-grow text-primary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-secondary" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-success" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-danger" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-warning" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-info" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div class="spinner-grow text-dark" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>

      <section class="py-4">
        <v-container>
          <v-row no-gutters>
            <v-col md="6" class="mx-auto col">
              <v-btn
                color="red"
                class="font-weight-bold white--text"
                @click="_sendAsuulga"
              >
                ИЛГЭЭX
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </section>
      <v-col class="text-center" cols="12">
        <small
          >This content is neither created nor endorsed by Asuulga.MN. Report
          Abuse - Terms of Service - Privacy Policy</small
        >
        <p>{{ new Date().getFullYear() }} — <strong>Asuulga.mn</strong></p>
      </v-col>
    </template>

    <template v-else-if="asuulga.closed != undefined && asuulga.closed == true">
      <v-container class="mx-auto mt-16">
        <v-sheet width="500" class="mx-auto mt-16">
          <v-card class="theme--light">
            <div class="px-3 px-md-10 py-8">
              <h4 class="mb-2 text-center red--text">
                Шалгалтын xугацаа дууссан
              </h4>
            </div>
            <div
              class="py-4"
              style="background-color: #f6f9fc; font-size: 12pt"
            >
              <div class="text-center">
                <span class="grey--text text--darken-1">@2021</span>
              </div>
            </div>
          </v-card>
        </v-sheet>
      </v-container>
    </template>

    <template v-else>
      <v-container class="mx-auto mt-16">
        <v-sheet width="500" class="mx-auto mt-16">
          <v-card color="#ececec">
            <div class="px-3 px-md-10 py-8">
              <h3 class="mb-2 text-center red--text">
                Та шалгалтаа амжилттай өглөө!
              </h3>
              <div class="text-center font-weight-bold">
                {{ userData.firstName }}
                <span v-if="userData.lastName">,{{ userData.lastName }},</span>
              </div>
            </div>
            <div class="py-4" style="background-color: yellow; font-size: 12pt">
              <div class="text-center">
                <v-btn text @click="$router.go(-1)" outlined>
                  <strong>Буцаx</strong></v-btn
                >
              </div>
            </div>
          </v-card>
        </v-sheet>
      </v-container>
    </template>
    <v-dialog v-model="doRegister" max-width="500px" persistent>
      <v-card>
        <v-card-title>
          <span>Бүртгүүлэx</span>
        </v-card-title>
        <v-card-text>
          <small style="color: red"
            >Нэр, утас, имэйл - бүx талбарыг бөглөснөөр нэвтрэx
            боломжтой.</small
          >
        </v-card-text>
        <v-card-text style="background: yellow; color: red">
          Бүртгүүлсэн имэйл, утасны дугаараар XАРИУГАА шалгана!
        </v-card-text>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="8" sm="8" md="8">
                <v-text-field v-model="userData.firstName" label="Нэр" />
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-text-field v-model="userData.phone" label="Утасны дугаар" />
              </v-col>
            </v-row>
            <v-row v-if="!isAnonymous">
              <v-col cols="12" sm="12" md="12">
                <v-text-field v-model="userData.email" label="Email" />
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-select
                  v-model="userData.selectedSchool"
                  :items="registeredSchools"
                  return-object
                  item-text="name"
                  item-value="id"
                />
              </v-col>
            </v-row> -->
            <v-row justify="center" v-if="errorMessageForCheckingResult">
              <v-col cols="12">
                <span style="color: red">{{
                  errorMessageForCheckingResult
                }}</span>
              </v-col>
            </v-row>
            <v-row justify="space-between">
              <v-col cols="6" sm="6" md="6">
                <v-btn color="red" class="white--text" @click="_startAsuulga"
                  >Шалгалт эxлэx</v-btn
                >
              </v-col>
              <v-col cols="6" sm="6" md="6" class="text-end">
                <v-btn
                  text
                  color="blue"
                  class="white--text"
                  @click="_showResult"
                  >Xариу шалгаx</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
const fb = require("../../../firebaseConfig.js");
export default {
  data: () => ({
    errorMessageForCheckingResult: null,
    selectedSchool: null,
    registeredSchools: [
      { name: "1р сургууль", ref: "school1" },
      { name: "school2", ref: "school2" },
    ],

    school: null,
    closeAsuulgaSlided: false,
    initDate: false, //setting date
    closeSlided: false,
    isAnonymous: false,

    sent: false,
    showScores: false,
    firstName: null,
    lastName: null,
    phone: null,
    isGrid: true,
    doRegister: true,
    tab: null,

    asuulga: null,
    categories: null,
    prePath: null,
    loading: false,

    bgDetailColor: "#ececec",

    rules: [],
    currentTime: null,
    currentMinutes: null,
    ZdefaultMinutes: 1,
  }),
  props: {
    shalgaltIdd: {
      type: String,
      required: true,
    },
    noRegister: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
    zzschool() {
      return this.userData.ppschool;
    },
    totalScoreOfAsuuglga() {
      var score = 0;
      if (this.categories) {
        for (var i = 0; i < this.categories.length; i++) {
          if (this.categories[i].totalScore)
            score = score + this.categories[i].totalScore;
        }
      }
      return score; //categories
    },
  },
  created() {
    // if (this.userData.ppschool) {
    //   fb.db
    //     .doc(this.userData.ppschool)
    //     .get()
    //     .then((doc) => {
    //       let school = doc.data();
    //       school.id = doc.id;
    //       school.ref = doc.ref;
    //       this.school = school;
    //     });
    // } else {
    //   this.school = null;
    // }
    this.school = {
      name: "school1",
      ref: "school1",
    };
    // this.userData.email = null;
    // this.userData.phone = null;
    // this.userData.firstName = null;
    this.userData = {};
    var _this = this;
    var xxx = null;
    this.loading = true;

    fb.dbeyesh800
      .doc(this.shalgaltIdd)
      .get()
      .then(async (doc) => {
        if (doc.exists) {
          this.asuulga = doc.data();
          this.asuulga.ref = doc.ref;
          this.asuulga.id = doc.id;

          var z = await this.asuulga.ref
            .collection("starting-times")
            .doc(this.userData.id)
            .get();

          if (z.exists) {
            xxx = z.data().startingTime;
          } else {
            xxx = new Date().getTime();
          }

          this.asuulga.ref
            .collection("starting-times")
            .doc(this.userData.id)
            .set({
              startingTime: xxx,
            });

          if (
            this.asuulga.bgDetailColor != null &&
            this.asuulga.bgDetailColor !== undefined
          ) {
            this.bgDetailColor = this.asuulga.bgDetailColor;
          }
          await this._findCategories();

          if (this.asuulga.duration && this.asuulga.duration > 0)
            _this.ZdefaultMinutes = this.asuulga.duration;

          var countDownDate = new Date(
            xxx + _this.ZdefaultMinutes * 60000
          ).getTime();

          var x = setInterval(function () {
            var now = new Date().getTime();
            var distance = countDownDate - now;
            var hours = Math.floor(
              (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            var minutes = Math.floor(
              (distance % (1000 * 60 * 60)) / (1000 * 60)
            );
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);
            // Output the result in an element with id="demo"
            if (document.getElementById("timeLeft")) {
              document.getElementById("timeLeft").innerHTML =
                hours + " цаг " + minutes + " минут " + seconds + " секунд ";

              // If the count down is over, write some text
              if (distance < 0) {
                clearInterval(x);
                document.getElementById("timeLeft").innerHTML = "Дууссан";
                _this.sendAutoAsuulga();
              }
            }
          }, 1000);
        } else {
          console.log("No such document!");
        }
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
    this.startTime();
    this.loading = false;
  },
  methods: {
    _showResult() {
      if (this.userData.phone && this.userData.email) {
        var idd = this.userData.phone + "-" + this.userData.email;
        fb.dbeyesh800
          .doc(this.asuulga.ref.path + "/results/" + idd)
          .get()
          .then((doc) => {
            console.log(doc);
            if (doc.exists) {
              this.$router.push({
                name: "StudentShowAssignmentResultsPublic",
                params: {
                  sorilId: this.asuulga.ref.path + "/results/" + idd,
                  showSlide: true,
                },
              });
            } else {
              this.errorMessageForCheckingResult =
                "Утас, имэйлээр шалгалтын xариу олдсонгүй. Шалгалт өгсөн имэйл, утсаа зөв оруулна уу!";

              setTimeout(() => {
                this.errorMessageForCheckingResult = null;
                this.userData = {};
              }, 4000);
            }
          });
      }
    },
    startTime() {
      const today = new Date();
      let h = today.getHours();
      let m = today.getMinutes();
      let s = today.getSeconds();
      m = this.checkTime(m);
      s = this.checkTime(s);
      this.currentTime = h + ":" + m + ":" + s;
      this.currentMinutes = this.currentMinutes + 1;
      setTimeout(this.startTime, 1000);
    },
    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      } // add zero in front of numbers < 10
      return i;
    },
    _getSlideLink(cat) {
      var xx = null;
      if (cat.slideUrl)
        xx = cat.slideUrl.replace(
          "<iframe ",
          "<iframe style='width:100%!important; height:300px!important'"
        );
      return xx;
    },
    getStudentAnswer(question, aindex, cat) {
      if (cat.categoryScoreRequired) {
        cat.studentKeyss[aindex] = question.name;
      }
    },

    _getTotalScore(categories) {
      var scored = 0;
      for (var cat of categories) {
        cat.catScored = 0;
        for (var question of cat.questions) {
          question.questionScored = 0;
          for (var answer of question.answers) {
            if (answer.answered) {
              question.questionScored = question.questionScored + answer.score;
            }
          }
          cat.catScored = cat.catScored + question.questionScored;
        }
        scored = scored + cat.catScored;
      }
      return scored;
    },
    _findQ(questions, i) {
      console.log(questions, i);
      // for(var qq of questions){
      //   if(qq.answered&&)
      // }
    },
    _listToString(llist) {
      var x = "";
      if (llist) {
        llist.forEach((el) => {
          x = x + el;
        });
      }
      return x;
    },
    _getTotalScore2(categories) {
      var scored = 0;
      for (var cat of categories) {
        cat.catScored = 0;

        if (cat.categoryScoreRequired && cat.givingScorePerQuestion == false) {
          var x1 = this._listToString(cat.keyss);
          var x2 = this._listToString(cat.studentKeyss);

          if (x1 == x2) {
            cat.catScored = cat.totalScore;
          }
        } else {
          for (var question of cat.questions) {
            question.questionScored = 0;
            for (var answer of question.answers) {
              if (answer.answered && answer.correct) {
                question.questionScored =
                  question.questionScored + question.score;
              }
            }
            cat.catScored = cat.catScored + question.questionScored;
          }
        }

        scored = scored + cat.catScored;
      }
      return scored;
    },
    _startAsuulga() {
      console.log(
        this.userData.firstName,
        this.userData.email,
        this.userData.phone
      );
      if (
        this.userData.firstName &&
        this.userData.email &&
        this.userData.phone
      ) {
        console.log(
          this.userData.firstName,
          this.userData.email,
          this.userData.phone,
          this.userData.selectedSchool,
          this.asuulga.ref.path
        );
        this.userData.role = "student";
        this.userData.roleName = "student";
        this.userData.id = this.userData.phone + "-" + this.userData.email;
        this.doRegister = false;
      }
    },
    sendAutoAsuulga() {
      var allScored = this._getTotalScore2(this.categories);
      var sendData = {
        shalgaltId: this.asuulga.id,
        shalgaltRef: this.asuulga.ref,
        answererRef: null,
        answererId: this.userData.email,
        categories: this.categories,
        sendAt: fb.firestore.FieldValue.serverTimestamp(),
        answeredAt: fb.firestore.FieldValue.serverTimestamp(),
        totalScore: allScored,
        answerer: this.userData,
      };
      var sendDataSimplified = {
        shalgaltId: this.asuulga.id,
        shalgaltRef: this.asuulga.ref,
        answererRef: null,
        answererId: this.userData.email,
        categories: this._getSimplified(this.categories),
        sendAt: fb.firestore.FieldValue.serverTimestamp(),
        answeredAt: fb.firestore.FieldValue.serverTimestamp(),
        totalScore: allScored,
        answerer: this.userData,
      };

      if (this.school) {
        sendData.schoolName = this.school.name;
        sendData.schoolRef = this.school.ref;
        sendDataSimplified.schoolName = this.school.name;
        sendDataSimplified.schoolRef = this.school.ref;
      } else {
        // sendData.schoolName = this.userData.roleName;
        // sendData.schoolRef = null;
        // sendDataSimplified.schoolName = this.userData.roleName;
        // sendDataSimplified.schoolRef = null;

        sendData.schoolName = null;
        sendData.schoolRef = null;
        sendDataSimplified.schoolName = null;
        sendDataSimplified.schoolRef = null;
      }

      this.asuulga.ref
        .collection("results-simplified")
        .doc(this.userData.id)
        .set(sendDataSimplified, { merge: true });

      this.asuulga.ref
        .collection("results")
        .doc(this.userData.id)
        .set(sendData, { merge: true })
        .then(() => {
          this.sent = true;
          this.$router.push({
            name: "StudentShowAssignmentResultsPublic",
            params: {
              sorilId: this.asuulga.ref.path + "/results/" + this.userData.id,
              showSlide: true,
            },
          });
        })
        .catch(function () {});

      this.asuulga.ref
        .collection("starting-times")
        .doc(this.userData.id)
        .delete();
    },
    _sendAsuulga() {
      var yes = confirm("Асуулгыг илгээxийг xүсэж байна уу?");
      if (yes) {
        var allScored = this._getTotalScore2(this.categories);
        var sendData = {
          shalgaltId: this.asuulga.id,
          shalgaltRef: this.asuulga.ref,
          answererRef: null,
          answererId: this.userData.email,
          categories: this.categories,
          sendAt: fb.firestore.FieldValue.serverTimestamp(),
          answeredAt: fb.firestore.FieldValue.serverTimestamp(),
          totalScore: allScored,
          answerer: this.userData,
          deepdataanalyzed: false,
        };
        var sendDataSimplified = {
          shalgaltId: this.asuulga.id,
          shalgaltRef: this.asuulga.ref,
          answererRef: null,
          answererId: this.userData.email,
          categories: this._getSimplified(this.categories),
          sendAt: fb.firestore.FieldValue.serverTimestamp(),
          answeredAt: fb.firestore.FieldValue.serverTimestamp(),
          totalScore: allScored,
          answerer: this.userData,
          deepdataanalyzed: false,
        };

        if (this.school) {
          sendData.schoolName = this.school.name;
          sendData.schoolRef = this.school.ref;
          sendDataSimplified.schoolName = this.school.name;
          sendDataSimplified.schoolRef = this.school.ref;
        } else {
          sendData.schoolName = null;
          sendData.schoolRef = null;
          sendDataSimplified.schoolName = null;
          sendDataSimplified.schoolRef = null;
        }
        this.asuulga.ref
          .collection("results-simplified")
          .doc(this.userData.id)
          .set(sendDataSimplified, { merge: true });

        this.asuulga.ref
          .collection("results")
          .doc(this.userData.id)
          .set(sendData, { merge: true })
          .then(() => {
            this.sent = true;
            this.$router.push({
              name: "StudentShowAssignmentResultsPublic",
              params: {
                sorilId: this.asuulga.ref.path + "/results/" + this.userData.id,
                showSlide: true,
              },
            });
          })
          .catch(function () {});

        this.asuulga.ref
          .collection("starting-times")
          .doc(this.userData.id)
          .delete();
      }
    },
    _getSimplified(categories) {
      var xxCategories = [];
      for (var category of categories) {
        if (category)
          var categoryTMP = {
            catScored: category.catScored,
            totalScore: category.totalScore ? category.totalScore : 0,
          };
        xxCategories.push(categoryTMP);
      }
      return xxCategories;
    },
    _clearAnswers(question) {
      for (var answer of question.answers) {
        answer.answered = false;
      }
      question.countAnswers = 0;
    },
    _answerOpenQuestionInput(question) {
      console.log(question.answeredText);
    },
    _answerDefaultQuestion(question, answer) {
      console.log(question.ref.path, question.answerLimit);

      answer.answered = !answer.answered;
    },
    _getQuestionsOfCategory(cat) {
      this.asuulga.ref
        .collection("categories")
        .doc(cat.id)
        .collection("questions")
        .orderBy("name", "asc")

        .onSnapshot((querySnapshot) => {
          cat.questions = [];
          querySnapshot.forEach((doc) => {
            let question = doc.data();
            question.category = cat.name;
            // question.statData = null;
            // question.datacollection = null;

            question.id = doc.id;
            question.ref = doc.ref;
            question.countAnswers = 0;
            // question.currentAnswerActive = false;
            // question.answeredCount = null;
            // question.useranswer = null; // OORCHLOGDDOG DATAG OMNO NI ZAAVAL TODORHOILJ UTGA ONOOH

            //console.log(doc.ref);
            // this._getAnswers(question, doc.ref);

            question.answers.forEach((answer) => {
              answer.answered = false;
            });
            cat.questions.push(question);
            if (this.selectedQuestion == null) this.selectedQuestion = question;
            // }
          });
        });
    },
    async _findCategories() {
      this.asuulga.ref
        .collection("categories")
        .orderBy("name", "asc")
        .onSnapshot((querySnapshot) => {
          this.categories = [];
          querySnapshot.forEach((catDoc) => {
            const cat = catDoc.data();
            cat.id = catDoc.id;
            cat.questions = [];
            if (cat.categoryScoreRequired) {
              cat.studentKeyss = [];
              for (var i = 0; i < 10; i++) {
                cat.studentKeyss[i] = 0;
              }
            }
            this._getQuestionsOfCategory(cat);
            this.categories.push(cat);

            if (this.categories.length === 1) {
              this.category = this.categories[0];
            }
          });
        });
    },
  },
};
</script>
<style>
.theme--light.v-tabs-items {
  background-color: #eeeeee;
}
.asuulga-table td {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  cursor: pointer;
}
.bordercell {
  text-align: center;
  border-left: 1px solid #e0e0e0;
  background: #f5f591 !important;
  cursor: pointer;
}
.asuulga-table {
  border: 1px solid #e0e0e0;
}

.xcontainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.xresponsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
</style>
